<template>
  <div class="maidong">

    <div class="top">
      <div class="ting">
        <div class="ting_title">奶厅1</div>
        <div class="pan_wrapper">
          <div class="pan" :class="{ active: activePan == '1' }" @click="clickPan('1')">
            <div class="title ">运行中</div>
            <div class="name">转盘1</div>
            <div class="numbers">
              <div class="num_item blue">
                <div class="num">60</div>
                <div class="num_name">点位数</div>
              </div>
              <div class="num_item green">
                <div class="num">50</div>
                <div class="num_name">正常</div>
              </div>
              <div class="num_item red">
                <div class="num">2</div>
                <div class="num_name">数据异常</div>
              </div>
              <div class="num_item yellow">
                <div class="num">2</div>
                <div class="num_name">通讯异常</div>
              </div>
              <div class="num_item gray">
                <div class="num">6</div>
                <div class="num_name">未运行</div>
              </div>
            </div>
          </div>
          <div class="pan" :class="{ active: activePan == '2' }" @click="clickPan('2')">
            <div class="title red">离线</div>
            <div class="name">转盘2</div>
            <div class="numbers">
              <div class="num_item blue">
                <div class="num">60</div>
                <div class="num_name">点位数</div>
              </div>
              <div class="num_item green">
                <div class="num">50</div>
                <div class="num_name">正常</div>
              </div>
              <div class="num_item red">
                <div class="num">2</div>
                <div class="num_name">数据异常</div>
              </div>
              <div class="num_item yellow">
                <div class="num">2</div>
                <div class="num_name">通讯异常</div>
              </div>
              <div class="num_item gray">
                <div class="num">6</div>
                <div class="num_name">未运行</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ting">
        <div class="ting_title">奶厅2</div>
        <div class="pan_wrapper">
          <div class="pan" :class="{ active: activePan == '3' }" @click="clickPan('3')">
            <div class="title">运行中</div>
            <div class="name">转盘3</div>
            <div class="numbers">
              <div class="num_item blue">
                <div class="num">60</div>
                <div class="num_name">点位数</div>
              </div>
              <div class="num_item green">
                <div class="num">50</div>
                <div class="num_name">正常</div>
              </div>
              <div class="num_item red">
                <div class="num">2</div>
                <div class="num_name">数据异常</div>
              </div>
              <div class="num_item yellow">
                <div class="num">2</div>
                <div class="num_name">通讯异常</div>
              </div>
              <div class="num_item gray">
                <div class="num">6</div>
                <div class="num_name">未运行</div>
              </div>
            </div>
          </div>
          <div class="pan" :class="{ active: activePan == '4' }" @click="clickPan('4')">
            <div class="title red">离线</div>
            <div class="name">转盘4</div>
            <div class="numbers">
              <div class="num_item blue">
                <div class="num">60</div>
                <div class="num_name">点位数</div>
              </div>
              <div class="num_item green">
                <div class="num">50</div>
                <div class="num_name">正常</div>
              </div>
              <div class="num_item red">
                <div class="num">2</div>
                <div class="num_name">数据异常</div>
              </div>
              <div class="num_item yellow">
                <div class="num">2</div>
                <div class="num_name">通讯异常</div>
              </div>
              <div class="num_item gray">
                <div class="num">6</div>
                <div class="num_name">未运行</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-con">

      <div class="m-select">
        <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
          <el-form-item>
            <el-select v-model="day" placeholder=" ">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>


      <div id="chart-con" class="chart">

      </div>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import * as echarts from "echarts";

const timeData = Array.from({ length: 7 }, (v, k) => {
  return dayjs().subtract(6 - k, 'day').format('YYYY-MM-DD')
});

export default {
  data() {
    return {
      activePan: '1',
      day: '7',
      options: [
        {
          value: '3',
          label: '三天',
        },
        {
          value: '5',
          label: '五天',
        },
        {
          value: '7',
          label: '七天',
        },
      ]
    }
  },
  methods: {
    clickPan(tab) {
      this.activePan = tab
    },
    setChart(data) {
      // let data = [
      //   {
      //     name: "上限",
      //     time: ["00:00", "00:01", "00:02", "00:03"],
      //     value: [1, 2, 3, 4],
      //   },
      // ];
      this.chart && this.chart.dispose();

      let dom = document.getElementById("chart-con");
      let chart = echarts.init(dom);
      let option;
      option = {
        title: {
          text: '奶厅1转盘1数据信息',
          textStyle: {
            color: "#fff",

          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: "circle",
          itemWidth: 15,
          itemHeight: 15,
          textStyle: {
            color: "#fff",
          },
          data: ['数据异常', '未运行', '通讯异常']
        },
        grid: {
          left: 20,
          bottom: 40,
          right: 100,
          top: 100,
          containLabel: true
        },
        tooltip: {
          confine: true,
          // trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#31c4f6",
          //   },
          // },
        },
        xAxis: {
          type: 'category',
          axisPointer: {
            type: "shadow",
          },
          boundaryGap: false,
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
          data: timeData
        },
        yAxis: [
          {
            scale: true,
            nameLocation: 'end',
            axisLine: {
              show: true,
              symbol: ['none', 'arrow'],
              symbolOffset:  40,
              lineStyle: {
                color: "#fff",
                shadowOffsetY: -40,
                shadowColor: "#fff",
              },
            },
            type: "value",
            name: "数量",
            nameGap: 60,
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 14,
            },
            // splitLine: {
            //   show: true,
            //   lineStyle: {
            //     color: "#28478c",
            //     type: "dashed",
            //   },
            // },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
            },
          },
        ],
        series: [
          {
            name: '数据异常',
            type: 'line',
            symbol: 'circle',
            symbolSize: '15',
            data: [5, 2, 1, 3, 9, 3, 6]
          },
          {
            name: '未运行',
            type: 'line',
            symbol: 'circle',
            symbolSize: '15',
            data: [2, 4, 1, 0, 0, 3, 10]
          },
          {
            name: '通讯异常',
            type: 'line',
            symbol: 'circle',
            symbolSize: '15',
            data: [5, 2, 2, 4, 10, 3, 5]
          },

        ]
      };
      chart.setOption(option);
      this.chart = chart;
    },
  },
  mounted() {

    this.setChart();
  },
  beforeUnmount() {
    this.chart && this.chart.dispose();
  },
}
</script>
<style scoped lang="less" src="./index.less"></style>